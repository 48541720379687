import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"
import Page1 from "./Page1"
import Page2_1 from "./Page2_1"
import Page2_2 from "./Page2_2"
import Page2_3 from "./Page2_3"
import Page2_4 from "./Page2_4"
import Page2_5 from "./Page2_5"

import "./styles/fullpageOverride.css"
import Page15_1 from "./Page15_1"
import ContactUs from "./ContactUs"

const App = () => {
  return (
    <>
      <header className={cn("top-header")}>
        <img
          src="bi_with_icon.svg"
          alt="snackpot"
          onClick={() => {
            window.location.reload()
          }}
        />
      </header>
      <ReactFullpage
        paddingTop={"60px"}
        easing={"easeInOutCubic"}
        easingcss3={"ease-in-out"}
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={700} /* Options here */
        afterLoad={(origin, destination, direction) => {}}
        render={({ state, fullpageApi }) => {
          return (
            <>
              <ReactFullpage.Wrapper>
                {/* <div className="section">
                  <Page1 />
                </div> */}
                <div className="section">
                  <ContactUs />
                </div>
                <div className="section">
                  <Page15_1 />
                </div>

                <div className="section">
                  <div className="slide">
                    <Page2_1 />
                  </div>
                  <div className="slide">
                    <Page2_2 />
                  </div>
                  <div className="slide">
                    <Page2_3 />
                  </div>
                  <div className="slide">
                    <Page2_4 />
                  </div>
                  <div className="slide">
                    <Page2_5 />
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            </>
          )
        }}
      />
    </>
  )
}

const cn = classNames.bind(styles)

export default App
