import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"
import styles_mobile from "./styles/brandpage_mobile.module.scss"

class Page2_1 extends React.Component {
  state = {
    videoType: "m",
  }

  componentDidMount() {
    const deviceWidth = window.screen.width
    window.addEventListener("resize", () => {
      if (deviceWidth > 500) this.setState({ videoType: "d" })
      else this.setState({ videoType: "m" })
    })
  }

  render() {
    const { videoType } = this.state

    return (
      <div className={cn("wrapper")}>
        <div className={cn("page2-1")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("section-title")}>
              스낵팟은 어떻게
              <br />
              세상을 바라볼까요?
            </div>
            <div className={cn("content-wrap")}>
              <video playsInline={true} muted data-autoplay loop>
                <source src={`./video/values_2_1_${videoType}.mp4`} type="video/mp4" media="all and (max-width: 500px)" />
              </video>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page2_1
