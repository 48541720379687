import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

export class Page15_1 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page15-1")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("section-title")}>
              <img src="logo.svg" alt="snackpot" />
              은 <br /> "언제 어디서나 스낵, 일상이 되다" 라는 슬로건 아래
              <br />
              다채롭고 개성 넘치는 스낵 경험을 제공합니다.
            </div>
            <div className={cn("content-wrap")}>
              <div className={cn("column")}>
                <ul>
                  <li>
                    <h3 className={cn("title")}>스낵 정보 검색</h3>
                    <h5 className={cn("desc-text")}>"세상 모든 스낵에는 특별한 이야기가 있어요"</h5>
                  </li>
                  <li>
                    <h3 className={cn("title")}>스낵 취향 분석 및 추천</h3>
                    <h5 className={cn("desc-text")}>"나도 모르는 나의 취향을 알 수 있어요"</h5>
                  </li>
                  <li>
                    <h3 className={cn("title")}>스낵 콘텐츠 제공</h3>
                    <h5 className={cn("desc-text")}>"스낵도 즐겁게 소비할 수 있도록"</h5>
                  </li>
                </ul>
              </div>
              <div className={cn("column")}>
                <ul>
                  <li>
                    <h3 className={cn("title")}>O2O 연계 서비스</h3>
                    <h5 className={cn("desc-text")}>“쇼핑이 더 즐거울 수 있게"</h5>
                  </li>
                  <li>
                    <h3 className={cn("title")}>6차산업 스낵 상품 발굴</h3>
                    <h5 className={cn("desc-text")}>“숨겨진 스낵을 함께 찾아봐요”</h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page15_1
