import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

export class ContactUs extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div id="kd" className={cn("wrapper")}>
        <div className={cn("contact-us")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("section-title")}>
              {/* <img src="logo.svg" alt="snackpot" /> */}
              Contact us
            </div>
            <div className={cn("content-wrap")}>
              <div className={cn("logos")}>
                <a href="https://www.instagram.com/snackpot_official" target="_blank" rel="noopener noreferrer">
                  <img src="./images/instagram.png" alt="instagram" />
                </a>
                <a href="http://blog.naver.com/steven30" target="_blank" rel="noopener noreferrer">
                  <img src="./images/blog.svg" alt="naver blog" />
                </a>
              </div>
              <div className={cn("contact-info")}>
                snackpot@snackpot.kr
                <br />
                <br />
                서울시 중구 퇴계로36나길
                <br />
                Toegye-ro 36na-gil,
                <br />
                Jung-gu, Seoul, Republic of Korea
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default ContactUs
