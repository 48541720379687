import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

class Page2_4 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page2-4")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("column-wrapper")}>
              <div className={cn("left-half")}>
                <video playsInline={true} muted data-autoplay loop>
                  <source src="/video/values_2_4.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={cn("right-half")}>
                <div className={cn("section-title")}>
                  스낵팟 크루는
                  <br />
                  목표를 향해 함께 갈 거에요.
                </div>
                <div className={cn("description-text")}>
                  나와 너는 다르지만, 같은 목표가 있어요.
                  <br />
                  나는 너와 다르니까, 항상 배울 점이 있어요.
                  <br />
                  스낵팟 크루라면 자신의 역량에 자신이 있듯이,
                  <br />
                  각자의 역량에 믿음을 가지고 있어요.
                  <br />
                  서로를 채워주면서 함께 더 큰 목표를 이룰 수 있어요.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page2_4
