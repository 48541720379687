import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

class Page2_3 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page2-3")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("column-wrapper")}>
              <div className={cn("left-half")}>
                <div className={cn("section-title")}>모든 다양성을 사랑합니다.</div>
                <div className={cn("description-text")}>
                  사람들은 수치가 아니에요.
                  <br />
                  이 세상도 0과 1로 이루어져 있지 않아요.
                  <br />
                  우리는 사람들 속에 있는 우주를 여행하고,
                  <br />
                  사람들도 우리의 우주를 여행해 줬으면 좋겠어요.
                  <br />
                  다양성은 그게 어떤 형태이든,
                  <br />
                  우리를 더 발전하게 해 주니까요.
                </div>
              </div>
              <div className={cn("right-half")}>
                <video playsInline={true} muted data-autoplay loop>
                  <source src="/video/values_2_3.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page2_3
