import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

class Page2_5 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page2-5")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("column-wrapper")}>
              <div className={cn("left-half")}>
                <div className={cn("section-title")}>
                  보다 더 많은 사람에게
                  <br />
                  좋은 서비스를 제공한다.
                </div>
                <div className={cn("description-text")}>
                  누군가 우리에게 문화를 물어본다면,
                  <br />
                  우리가 목표하는 걸 말하겠죠.
                  <br />
                  모든 행동 속에는
                  <br />
                  더 많은 사람들에게, 더 좋은 서비스를 보여주고 싶은 우리의 목표가 있으니까요.
                  <br />
                  그래서 우리는 항상 <br /> ‘더 좋은 서비스는 없을까?’를 질문합니다.
                  <br />
                  그래야만 목표로 한 걸음 더 나아갈 수 있겠죠.
                </div>
              </div>
              <div className={cn("right-half")}>
                <video playsInline={true} muted data-autoplay loop>
                  <source src="/video/values_2_5.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page2_5
