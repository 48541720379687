import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

class Page1 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page1")}>
          <video id="videobg" muted autoPlay loop className={cn("video-bg")} data-autoplay>
            <source src="./video/main_video.mp4" type="video/mp4" />
          </video>
          <div className={cn("cp")}>
            언제 어디서나
            <br />
            스낵, 일상이 되다.
          </div>
          <div className={cn("overlay")}></div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page1
