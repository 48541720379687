import React from "react"
import classNames from "classnames/bind"

import styles from "./styles/brandpage.module.scss"

class Page2_2 extends React.Component {
  render() {
    return (
      <div className={cn("wrapper")}>
        <div className={cn("page2-2")}>
          <div className={cn("gray-rectangle")}>
            <div className={cn("column-wrapper")}>
              <div className={cn("left-half")}>
                <video playsInline={true} muted data-autoplay loop>
                  <source src="/video/values_2_2.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={cn("right-half")}>
                <div className={cn("section-title")}>
                  우리는
                  <br /> 세상을 조금 더 좋은 방향으로
                  <br /> 이끌어가고 싶어요.
                </div>
                <div className={cn("description-text")}>
                  '이런 서비스가 생기면 좋겠다'
                  <br />
                  모든 것의 시작은 이 질문일 거에요.
                  <br />
                  우리는 언제나 사람들이 원하는 것을 알아가고 싶고, <br />
                  치열하게 궁금해하고 연구하고 있어요.
                  <br />
                  세상에 좋은 서비스를 선보여서
                  <br />
                  이곳을 조금이라도 행복한 공간으로 만들고 싶어요.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Page2_2
